import "./bootstrap";
import "../css/app.css";

import { createApp, h } from "vue";
import { createInertiaApp } from "@inertiajs/vue3";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import { ZiggyVue } from "../../vendor/tightenco/ziggy/dist/vue.m";

import * as Sentry from "@sentry/vue";
const appName =
    window.document.getElementsByTagName("title")[0]?.innerText || "Laravel";

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) =>
        resolvePageComponent(
            `./Pages/${name}.vue`,
            import.meta.glob("./Pages/**/*.vue")
        ),
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) });
        /*Sentry.init({
            createdApp,
            dsn: "https://72055c0c18d884bc95253efd345b6be0@o7288.ingest.us.sentry.io/4508216312004608",
            integrations: [],
        });*/

        const isProduction = import.meta.env.MODE === "production"; // Store environment value in a variable

        const dsn = import.meta.env.VITE_SENTRY_LARAVEL_DSN;
        // console.log("isProduction", isProduction);
        // console.log("dsn", dsn);
        if (isProduction) {
            const integrations = [];
            Sentry.init({
                app,
                dsn: dsn,
                integrations: integrations,

                // Set tracesSampleRate to 1.0 to capture 100%
                // of transactions for performance monitoring.
                // We recommend adjusting this value in production
                // tracesSampleRate: 0.5,

                // Session Replay
                // replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
                // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

                ignoreErrors: [
                    // Random plugins/extensions
                    "top.GLOBALS",
                    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
                    "originalCreateNotification",
                    "canvas.contentDocument",
                    "MyApp_RemoveAllHighlights",
                    "http://tt.epicplay.com",
                    "Can't find variable: ZiteReader",
                    "jigsaw is not defined",
                    "ComboSearch is not defined",
                    "http://loading.retry.widdit.com/",
                    "atomicFindClose",
                    // Facebook borked
                    "fb_xd_fragment",
                    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
                    // reduce this. (thanks @acdha)
                    // See http://stackoverflow.com/questions/4113268
                    "bmi_SafeAddOnload",
                    "EBCallBackMessageReceived",
                    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
                    "conduitPage",
                ],
                denyUrls: [
                    // RBR
                    /posthog\.com/i,
                    /eu.i.posthog\.com/i,
                    /mapbox\.com/i,
                    /api.mapbox\.com/i,
                    /events.mapbox\.com/i,
                    /tawk\.to/i,
                    /va.tawk\.to/i,
                    /embed.tawk\.to/i,
                    // Facebook flakiness
                    /graph\.facebook\.com/i,
                    // Facebook blocked
                    /connect\.facebook\.net\/en_US\/all\.js/i,
                    // Woopra flakiness
                    /eatdifferent\.com\.woopra-ns\.com/i,
                    /static\.woopra\.com\/js\/woopra\.js/i,
                    // Chrome extensions
                    /extensions\//i,
                    /^chrome:\/\//i,
                    /^chrome-extension:\/\//i,
                    // Other plugins
                    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
                    /webappstoolbarba\.texthelp\.com\//i,
                    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
                ],
            });

            if (
                props.initialPage.props.auth &&
                props.initialPage.props.auth.user
            ) {
                // console.log("props.initialPage.props.user", props.initialPage.props.user);
                Sentry.setUser({
                    // fullName: props.initialPage.props.user.name,
                    // name: props.initialPage.props.user.name,
                    email: props.initialPage.props.auth.user.email,
                    // id: props.initialPage.props.user.id,
                });
            }

            // setTimeout(() => fn(), 10000);
        }

        return app.use(plugin).use(ZiggyVue, Ziggy).mount(el);
    },
    progress: {
        color: "#4B5563",
    },
});
